@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700;900&display=swap');

* {
  scroll-behavior: smooth;
  font-family: 'Lato', sans-serif;
}

::-webkit-scrollbar {
  width: 6px;
  background-color: #f5f5f5;
  visibility: hidden;
}

.phone-input:focus {
  outline: none !important;
}

.ant-btn.ant-btn-primary {
  background-color: #7c0000;
}

//css from index file

.header-text-3 {
  color: #484442;

  /* Heading/3 */
  font-family: Lato;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px; /* 125% */
}
.header-text-4 {
  color: #484442;

  /* Heading/3 */
  font-family: Lato;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 125% */
}
.body-text-2 {
  color: #484442;

  /* Body/2 */
  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.btn-small-primary {
  border-radius: var(--spacing-3, 8px);
  background: var(--primary-orange500, #a53d00);
  display: flex;
  padding: var(--spacing-3, 8px) var(--spacing-5, 16px);
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-1, 2px);
}

.btn-small-outline {
  display: flex;
  padding: var(--spacing-3, 7px) var(--spacing-5, 16px);
  justify-content: center;
  align-items: flex-start;
  gap: var(--spacing-1, 2px);
  border-radius: var(--spacing-3, 8px);
  border: 1px solid var(--primary-orange500, #a53d00);
  color: #a53d00;
  background: var(--neutral-0, #fff);
}

.input {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-radius: 8px;
  border: 1px solid #939291;
  background: #fff;

  &:focus {
    outline: none;
    border: none;
  }
}

.btn-subtle {
  color: #a53d00;
  text-align: center;

  font-family: Lato;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px; /* 128.571% */
}

.header {
  position: sticky;
  top: 0;
  display: flex;
  z-index: 1000;
  align-items: center;
  background: var(--neutral-50, #fff);
  height: 44px;
  /* shadow 4 */
  box-shadow: 0px 32px 64px 0px rgba(0, 0, 0, 0.1);
}

.wallet-container {
  background: url('./images/wallet.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.coupon-details-container {
  background: url('./images/coupon-card.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

// .nav-bar {
//   display: inline-flex;
//   padding: var(--spacing-5, 16px) var(--spacing-8, 40px);
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   gap: 25px;
//   border-top: 1px solid var(--primary-orange300, #ffb080);
//   background: var(--neutral-0, #fff);
//   box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.12);
// }

.radio-btn {
  span {
    color: #a53d00;
  }
}

.text-header-4 {
  color: var(--neutral-300, #484442);

  /* Heading/4 bold */

  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px; /* 133.333% */
}

.text-caption {
  color: var(--neutral-300, #484442);

  /* Caption */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 14px; /* 116.667% */
}

.input-container {
  display: flex;
  height: 50px;
  padding: var(--spacing-3, 8px);
  justify-content: center;
  align-items: center;
  min-width: 100%;
  border-radius: var(--spacing-3, 8px);
  border: 1px solid var(--neutral-200, #939291);
  background: var(--neutral-0, #fff);

  &:focus {
    border: 1px solid var(--neutral-200, #ccc);
  }
  &:disabled {
    color: 'black';
  }
}

.card-small-container {
  padding: var(--spacing-4, 12px);
  // flex-direction: column;
  // align-items: flex-start;
  gap: var(--spacing-2, 4px);
  border-radius: var(--spacing-3, 12px);
  // border: 1px solid var(--neutral-100, gray);
  background: var(--neutral-0, #fff);

  /* shadow 1 */
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.1);
}

.coupon-card-container {
  border-radius: var(--spacing-5, 16px);
  background: var(--neutral-0, #fff);
  padding: 12px;
  /* shadow 2 */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
}

.colored-card-container {
  display: flex;
  width: 40px;
  height: 40px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: var(--spacing-3, 8px);
}

// react-interntional-phone-input
.react-international-phone-input-container
  .react-international-phone-country-selector-button {
  border: 1px solid #939291;
  border-right: none;
  margin-right: -1px;
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding: 8px;
  height: 50px;
}

.react-international-phone-input-container
  .react-international-phone-input:focus-within {
  &:focus {
    --tw-ring-color: transparent;
  }
}

//ant design drawer component design
.ant-drawer .ant-drawer-body {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-y: hidden;
}

//and design toggler
.ant-switch .ant-switch-handle::before {
  position: absolute;
  top: 0;
  inset-inline-end: 0;
  bottom: 0;
  inset-inline-start: 0;
  background-color: #484442;
  border-radius: 9px;
  box-shadow: 0 2px 4px 0 rgba(0, 35, 11, 0.2);
  transition: all 0.2s ease-in-out;
  content: '';
}

.ant-skeleton .ant-skeleton-content {
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

button {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* You can do the same for links */
div {
  -webkit-tap-highlight-color: transparent;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

/* antD select */
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: none;
  background: none;
  box-shadow: none;
  outline: none;
}

// .ant-select-outlined:not(.ant-select-disabled):not(
//     .ant-select-customize-input
//   ):not(.ant-pagination-size-changer):hover
//   .ant-select-selector {
//   border-color: #ccc;
//   box-shadow: none;
// }

.ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.88);
  font-weight: 600;
  background-color: transparent;
}

#qr-div svg {
  width: 100px;
  height: 100px;
}

/* HTML: <div class="loader"></div> */
.loader {
  width: 25px;
  padding: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #a53d00;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

//select coupon dropdown
.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-active {
  background-color: white;
}

.ant-dropdown .ant-dropdown-menu {
  padding: 0;
  margin-right: 20px;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
  padding: 5px;
}

.ant-checkbox .ant-checkbox-input {
  opacity: 1;
  z-index: 0;
  outline: #a53d00;
}
